<template>
  <v-card class="ma-4">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card-text class="pt-0">
      <v-data-table
        id="services-table"
        :headers="layers.headers"
        :items="layers.customerLayers"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              dark
              @click="createButtonEvent"
            >
              New Layer
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editButtonEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="translationButtonEvent(item)"
          >
            mdi-google-translate
          </v-icon>
          <delete
            :id="item.name"
            @deleted="deleteButtonEvent(item)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },

  data() {
    return {
      search: '',
      loading: false,
      overlay: false,
      tableItems: []
    };
  },
  computed: {
    ...mapState(['layers', 'customer'])
  },
  async mounted() {
    this.loading = true;

    await this.storeCustomerLayers(this.customer.crm_id);

    this.loading = false;
  },
  methods: {
    ...mapActions(['storeCustomerLayers', 'deleteCustomerLayerAction']),
    async deleteButtonEvent(item) {
      this.overlay = true;

      const data = {
        global_layer_id: item.global_layer_id,
        crm_id: this.customer.crm_id
      };
      await this.deleteCustomerLayerAction(data);
      this.storeCustomerLayers(this.customer.crm_id);

      this.overlay = false;
    },
    editButtonEvent(item) {
      this.$emit('editLayerEvent', item);
    },
    createButtonEvent() {
      this.$emit('createLayerEvent');
    },
    translationButtonEvent(item) {
      this.layers.isCustomerLayerTranslating = true;
      this.$emit('translationLayerEvent', item);
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
